import React, { useEffect, useRef, useState } from 'react';
import { Input, Modal, Spin, Checkbox } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Attributes from '../../redux/middleware/attributes';
import Delete from '../../assets/images/Delete.svg';
import RecipeStepDropdown from '../../components/CookingMethodDropdown';

import './style.scss';
import { handleFloat } from '../../helper/sortData';

type Props = {
  steps: any;
  addSteps: any;
  handleDeleteStep: any;
  description: any;
  image: any;
  handleImage: any;
  handleDescription: any;
  stepIndex: any;
  stepDeleteDialog: any;
  handleDeleteDialog: any;
  handleChangeStep: any;
  handleIndex: any;
  calory: any;
  fat: any;
  carb: any;
  protein: any;
  recipeNote: any;
  video: any;
  handlecalory: any;
  handlecarb: any;
  handlefat: any;
  handleprotein: any;
  handleNote: any;
  handlevideo: any;
  prep: any;
  serving: any;
  handleprep: any;
  handleserving: any;
  handleallergenCheck: any;
  handleaddionalinfocheck: any;
  CookingVariation: any;
  searchedValue: any;
  showRecipeView: any;
  recipes: any;
  handleChangeSearchedValue: any;
  handleSelectOption: any;
  handleDeleteRecipe: any;
  handleAddItem: any;
  handleAddRecipeStep: any;
  handleDeleteRecipeStep: any;
  handleStepValue: any;
  allergen: any;
  blockedForEdit: any;
  addinfo: any;
  onChangeCookingMenu: any;
  cookingMenu: any;
  errorList: any;
  errorStatus: boolean;
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const RecipeStepTwo = (props: Props) => {
  let {
    steps,
    description,
    addSteps,
    handleDeleteStep,
    calory,
    fat,
    image,
    carb,
    protein,
    recipeNote,
    handleDescription,
    stepDeleteDialog,
    handleDeleteDialog,
    handleChangeStep,
    // handleIndex,
    handlecalory,
    handlecarb,
    handlefat,
    handleprotein,
    handleNote,
    prep,
    serving,
    handleprep,
    handleserving,
    handleImage,
    handleallergenCheck,
    handleaddionalinfocheck,
    CookingVariation,
    searchedValue,
    showRecipeView,
    recipes,
    handleChangeSearchedValue,
    handleSelectOption,
    handleDeleteRecipe,
    handleAddItem,
    handleAddRecipeStep,
    handleDeleteRecipeStep,
    handleStepValue,
    allergen,
    addinfo,
    onChangeCookingMenu,
    cookingMenu,
    blockedForEdit,
    // errorList,
    // errorStatus,
  } = props;
  const [spinner, setSpinner] = useState(true);
  const [allergenData, setAlergenData] = useState<any[]>([]);
  const [allergenSelected, setAllergenSelected] = useState<number[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);
  const fileRef = React.useRef<HTMLInputElement>(null);
  const inputRefs = useRef<any>(steps?.map(() => React.createRef()));

  useEffect(() => {
    fetchAllergen();
    fetchAdditionalData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (attributes.length && allergenData.length) setSpinner(false);
  }, [allergenData, attributes]);

  const fetchAdditionalData = async () => {
    const data = await Attributes.getAdditionalInfo();
    setAttributes(data?.data);
  };

  const fetchAllergen = async () => {
    const data = await Attributes.getAllergens();
    setAlergenData(data?.data);
  };

  const handleKeyDown = (e: any, index: number) => {
    if (e.key === 'Enter') {
      // Set focus to next text input
      addSteps();
      setTimeout(() => inputRefs.current[index + 1].focus(), 200);
      // inputRefs.current[index + 1].focus();
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    val: any
  ) => {
    let updatedSelection: any[] = [];
    updatedSelection = allergenSelected.includes(val.id)
      ? allergenSelected.filter((id: any) => id !== val.id)
      : [...allergenSelected, val.id];
    setAllergenSelected(updatedSelection);
    handleallergenCheck(e, val);
  };

  const { TextArea } = Input;

  return (
    <>
      <Modal
        title={<div className="title font">Confirmation</div>}
        centered
        open={stepDeleteDialog}
        onCancel={() => handleDeleteDialog(false)}
        width={480}
        footer={null}
        className="DialogHeight"
      >
        <div className="deleteDialogText">
          Are you sure want to delete that step?
        </div>
        <div className="butonGroupDialog">
          <div
            className="wizard-button"
            onClick={() => handleDeleteDialog(false)}
          >
            Cancel
          </div>
          <div className="continue wizard-button" onClick={handleDeleteStep}>
            Continue
          </div>
        </div>
      </Modal>
      <div className="recipeStepTwo-Head">Ingredient Information</div>
      <div className="recipeStepTwo">
        <div className="recipeWizardLabel">Upload Image</div>
      </div>
      {image ? (
        <div className="recipeImageUpload">
          <div className="recipeImageWrapper">
            <img src={image} alt="" className="recipeImageHeight" />
            <div
              className={
                blockedForEdit ? 'hideButtonForRecipe' : 'deleteButtonForRecipe'
              }
              onClick={() => handleImage('', {})}
            >
              <img src={Delete} width={15} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="recipeFormgroup formgroupStepOne">
          <div className="recipe-label-wrapper">
            <label>
              <p className="ant-upload-text ">Drop your image here or browse</p>
              <p className="ant-upload-hint">
                Minimum file 2 MB (.png,.jpg,.jpeg)
              </p>
              <input
                ref={fileRef}
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={async (e: any) => {
                  const response = await getBase64(e.currentTarget.files[0]);
                  if (response) handleImage(response, e);
                }}
              />
            </label>
          </div>
        </div>
      )}
      <div className="recipeStepTwo ">
        <div className="recipeWizardLabel">Description</div>
        <TextArea
          rows={4}
          className="text-Area"
          placeholder="Input description here"
          autoSize={{ minRows: 5, maxRows: 6 }}
          value={description}
          onChange={(e) => handleDescription(e.target.value)}
        />
      </div>
      <div className="recipeStepTwo checkBox">
        <Checkbox
          onChange={onChangeCookingMenu}
          value={cookingMenu}
          checked={cookingMenu}
        >
          Multiple Cooking Methods
        </Checkbox>
      </div>
      {cookingMenu ? (
        <RecipeStepDropdown
          CookingVariation={CookingVariation}
          searchedValue={searchedValue}
          showRecipeView={showRecipeView}
          recipes={recipes}
          handleChangeSearchedValue={handleChangeSearchedValue}
          handleSelectOption={handleSelectOption}
          handleDeleteRecipe={handleDeleteRecipe}
          handleAddItem={handleAddItem}
          handleAddRecipeStep={handleAddRecipeStep}
          handleDeleteRecipeStep={handleDeleteRecipeStep}
          handleStepValue={handleStepValue}
        />
      ) : (
        <>
          <div className="recipeStepTwo-Head">Cooking Instruction</div>
          <div className="recipeStepTwo ">
            <div className="recipeWizardLabel">Steps</div>

            {steps?.map((data: any, index: number) => (
              <div className="recipe-stepTwo-input" key={index}>
                <div>{index + 1}</div>
                <Input
                  placeholder="Enter field name"
                  value={data}
                  onChange={(e: any) => handleChangeStep(e.target.value, index)}
                  onKeyDown={(e: any) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                />

                <img
                  src={Delete}
                  width={15}
                  alt=""
                  onClick={() => {
                    // handleIndex(index);
                    // handleDeleteDialog(true);
                    handleDeleteStep(index);
                  }}
                />
              </div>
            ))}
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
            <div className="recipeStepAddButton" onClick={addSteps}>
              + Add New
            </div>
          </div>
        </>
      )}
      <div className="recipeStepTwo recipeTextArea">
        <div className="recipeWizardLabel">Recipe Notes</div>

        <TextArea
          rows={4}
          className="text-Area"
          placeholder="Input recipe notes here"
          autoSize={{ minRows: 5, maxRows: 5 }}
          value={recipeNote}
          onChange={(e) => handleNote(e.target.value)}
        />
      </div>
      <div className="recipeStepTwo-Head">Advanced</div>
      <div className="recipePracticeContainer recipePracticeContainerJustify">
        <div className="recipeSubContainerPractice recipePracticeText">
          <div className="recipePracticeLabel">Prep Time (Mins)</div>
          <Input
            placeholder="Enter prep time"
            value={handleFloat(prep)}
            type="text"
            onChange={(e) => {
              const value = e.target.value;
              if (value === '') {
                handleprep('');
              } else {
                const floatValue = parseFloat(value);
                if (!isNaN(floatValue)) {
                  handleprep(floatValue);
                }
              }
            }}
          />
          {/* {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['recipe_name']}
            </div>
          )} */}
        </div>
        <div className="recipeSubContainerPractice recipePracticeText">
          <div className="recipePracticeLabel">
            Serving <span className="sterik">*</span>
          </div>
          <Input
            placeholder="Enter serving"
            value={serving}
            type="number"
            onChange={(e) => handleserving(e.target.value)}
          />
          {/* {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['recipe_name']}
            </div>
          )} */}
        </div>
      </div>
      <div className="recipeWizard-subHead">Nutritions Facts</div>
      <div className="recipeWizardSubContainer">
        <div className="recipeWizardadditonal">
          <div>Calories</div>
          <div>Carbs</div>
          <div>Fat</div>
          <div>Protein</div>
        </div>
        <div className="recipeUnitContainer">
          <div className="recipeBorderContainer">
            <div className="recipeUnitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                disabled={true}
                value={calory ? +calory.toFixed(2) : ''}
                onChange={(e) => handlecalory(e.target.value)}
              />
              <div className="unitColor">kcal</div>
            </div>
          </div>
          <div className="borderContainer">
            <div className="unitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                disabled={true}
                value={carb ? +carb.toFixed(2) : ''}
                onChange={(e) => handlecarb(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
          <div className="borderContainer">
            <div className="unitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                disabled={true}
                value={fat ? +fat.toFixed(2) : ''}
                onChange={(e) => handlefat(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
          <div className="borderContainer">
            <div className="unitSubContainer ">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                disabled={true}
                value={protein ? +protein.toFixed(2) : ''}
                onChange={(e) => handleprotein(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
        </div>
        {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_name']}
          </div>
        )} */}
      </div>
      {spinner ? (
        <Spin className="loadIngredient" />
      ) : (
        <div className="wizardLabelAdvance">
          <div className="recipeStepTwo allergens">Allergens</div>
          <ul className="allergens-content">
            {allergenData.map((val, index) => {
              const isSelected = allergen.some(
                (data: any) => data?.id === val?.id
              );
              return (
                <li key={index}>
                  <input
                    type="checkbox"
                    checked={isSelected || allergenSelected.includes(val?.id)}
                    disabled={isSelected && !allergenSelected.includes(val?.id)}
                    onChange={(e) => handleCheckboxChange(e, val)}
                    className="checkboxIngredient"
                  />
                  <label>{val?.name}</label>
                </li>
              );
            })}
          </ul>
          {/* {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['recipe_name']}
            </div>
          )} */}
        </div>
      )}
      {spinner ? (
        <Spin />
      ) : (
        <div className="recipeStepTwo">
          <div className=" wizardLabelAdvance allergens">Additional Info</div>
          <ul className="allergens-content">
            {attributes?.map((val, index) => {
              return (
                <li key={index}>
                  <input
                    type="checkbox"
                    onChange={(e) => handleaddionalinfocheck(e, val)}
                    className="checkboxIngredient"
                    checked={
                      addinfo.filter((data: any) => data?.id === val?.id).length
                        ? true
                        : false
                    }
                  />
                  <label className="addtionalAttributeLabel">
                    {val?.attribute_name}
                  </label>
                </li>
              );
            })}
          </ul>
          {/* {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['recipe_name']}
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default RecipeStepTwo;
