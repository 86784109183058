/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  MenuProps,
  // PaginationProps,
  Select,
  Spin,
  Dropdown as DropDown,
  Space,
  Radio,
  RadioChangeEvent,
  // Input,
  Checkbox,
  InputNumber,
  Tooltip,
} from 'antd';
import {
  Page,
  Breadcrumb,
  // Pagination,
  ResponseDialog,
  ListViewWithoutExpand,
  Confirmation,
} from '../../components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import SupplierActions from '../../redux/middleware/supplier';
import Utils from '../../redux/utils';
// import Eye from '../../assets/images/Eye.svg';
import { handleFloat } from '../../helper/sortData';
import './purchaseOrder.scss';
import { useSelector } from 'react-redux';
import { checkAuthentication } from '../../helper/checkAuthentication';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { formatNumber, sortedData, sortNumber } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';

const PurchaseOrderWizard = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  // const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [sortData, setSortData] = useState([{}]);
  const [ascending, setAscending] = useState(true);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [supplierId, setSupplierId] = useState<any>();
  const [selectedSupplier, setSelectedSupplier] = useState<any>();
  const [selectedSupplierId, setSelectedSupplierId] = useState<any>(null);
  const [supplierAlert, setSupplierAlert] = useState(false);
  const [branchAlert, setBranchAlert] = useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const shop = Utils.getCurrentShop();
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [ingredientIndex, setIngredientIndex] = useState<any>();
  const [confirmation, setConfirmation] = useState(false);
  const [ingredientName, setIngredientName] = useState('');
  const [ingredientData] = useState<any[]>([]);
  const [requestType, setRequestType] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [ingredients, setIngredients] = useState<any[]>([
    {
      ingredient_id: -1,
      unit_id: 1,
      quantity: '',
      ingredient_name: '',
      storage_unit: '',
      multiplier: 0,
    },
  ]);
  const [totalPrice, setTotalPrice] = useState<number>();
  const [purchaseOrderFor, setPurchaseOrderFor] = useState(
    user?.roles[0]?.id === 3 ? 'brand' : ''
  );
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dataLength, setDataLength] = useState(50);
  const [spinner, setSpinner] = useState(true);

  // const [messages, setMessages] = useState<any[]>([]);
  // const [alerts, setAlerts] = useState(false);
  // const [visible, setVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [unit, setUnit] = useState<any>([]);
  // const PageDataLength = [6, 10, 20, 50];

  const items: MenuProps['items'] = unit?.map((data: any) => {
    return {
      key: `${data?.id}`,
      label: <div className="dropdownOptions">{data?.unit}</div>,
    };
  });

  const { currentShop } = useSelector((state: any) => state.Auth);

  // const items: MenuProps['items'] = unit?.map((data: any) => {
  //   return {
  //     key: `${data?.id}`,
  //     label: <div className="dropdownOptions">{data?.unit}</div>,
  //   };
  // });

  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentShop?.shop_id);

  useEffect(() => {
    if (update && currentBrandId !== currentShop?.shop_id) {
      navigate('/app/purchaseOrder');
    }
    getSupplier();
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (dataLength && !update) {
      getIngredients(selectedSupplier || supplierId || selectedSupplierId);
    }
  }, [dataLength, currentBrand, currentShop, purchaseOrderFor]);

  const handleBack = () => {
    navigate('/app/purchaseOrder');
  };

  const onChangeBrandBranch = (e: RadioChangeEvent) => {
    if (shop?.is_ck === 0) {
      let responseDialogData;
      responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          `The ${shop?.shop_name} selected is not a Central Kitchen, so this operation is not allowed to this Branch`,
        ],
      };
      ResponseDialog(responseDialogData);
      return;
    }
    setPurchaseOrderFor(e.target.value);
  };

  const getIngredients = async (supplierid: number) => {
    const shop = await Utils.getCurrentShop();
    setSpinner(true);
    const data =
      await SubRecipeActions.getSubRecipeInventoryForPurchaseOrderWizard(
        3,
        '',
        supplierid,
        shop?.shop_id ? shop?.shop_id : '',
        purchaseOrderFor
      );
    if (data != undefined && data?.success) {
      setIngredients(data?.data?.items);
      setTotal(data?.data?.pagination?.total);
      setSortData(data?.data?.items);
    } else {
      setIngredients([]);
      setSortData([]);
      setTotal(0);
    }
    setSpinner(false);
  };

  const getSupplier = async () => {
    const supplierData = await SupplierActions.getSupplierOfShop(
      500,
      '',
      shop?.shop_id
    );
    if (supplierData?.success) {
      setSuppliers(
        supplierData?.data?.items?.map((data: any) => {
          return {
            label: data?.supplier_name,
            value: data?.id,
          };
        })
      );
      // setSupplierAlert(true);
      setBranchAlert(false);
    } else {
      setSupplierAlert(false);
      setBranchAlert(true);
    }
    if (update) {
      setSupplierAlert(false);
    }
  };

  const handleSupplier = (value: any) => {
    setSelectedSupplierId(value);
    if (value) {
      getIngredients(value);
      setSupplierAlert(false);
      const selectedSupplier = suppliers.find(
        (supplier) => supplier?.value === value
      );
      if (update && selectedSupplier) {
        setSelectedSupplier(selectedSupplier?.label);
        setSelectedSupplierId(value);
      }
    }
    // const data: any = suppliers.filter((data: any) => {
    //   return data.value === value;
    // });
    //   setBrandId(data.flat());
  };

  React.useEffect(() => {
    checkAuthentication(user, navigate);
    fetchStatus();
    if (update) {
      setApiLoader(true);
      fetchDataById(id);
    }
  }, []);

  const fetchDataById = async (id: any) => {
    const { data, success } = await SupplierActions.getInventorySupplyById(id);
    if (success) {
      // setIngredients(
      //   data?.ingredients?.map((ingredient: any) => ({
      //     ...ingredient,
      //     quantity: ingredient?.request_quantity,
      //   }))
      // );
      setSortData(data?.ingredients);
      setIngredients(data?.ingredients);
      setSupplierId({
        label: data?.supplier_name,
        value: data?.po_supplier_id,
      });
      setPurchaseOrderFor(data?.po_from);
      setSelectedSupplierId(data?.po_supplier_id);
      setStatus({ status: data?.request_status, id: data?.request_status_id });
      setRequestType({
        status: data?.request_type_id,
        id: data?.request_type_id,
      });
      setApiLoader(false);
    }
  };

  const fetchStatus = async () => {
    const { success, data } = await SupplierActions.getSupplyStatus(
      'request_type'
    );
    if (success && data?.length > 0)
      setRequestType(data?.find((type: any) => type?.slug == 'purchase_order'));

    const response = await SupplierActions.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  const handleContinue = async () => {
    window.scrollTo(0, 0);
    // setErrorList({});
    // setErrorStatus(false);

    const checkIngredient = checkEmptyIngredients(2);
    if (checkIngredient) {
      return false;
    }
    if (
      requestType?.id === undefined ||
      supplierId === '' ||
      selectedItems.length == 0 ||
      selectedItems[0]?.ingredient_name == ''
    ) {
      setVisible(true);
      return;
    }

    const user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const company = await Utils.getCurrentCompany();

    const selectedIngredients = ingredients.filter((ingredient) =>
      selectedItems.some((item: any) => item.id === ingredient.id)
    );

    const payload = {
      request_type_id: requestType?.id,
      request_status_id: status?.id,
      po_from:
        user?.roles[0]?.name === 'Company Manager'
          ? purchaseOrderFor
          : user?.roles[0]?.name === 'Branch Manager'
          ? 'shop'
          : 'brand',
      po_from_id:
        purchaseOrderFor === 'brand'
          ? brand?.brand_id
          : user?.roles[0]?.name === 'Branch Manager'
          ? shop?.shop_id
          : user?.roles[0]?.name === 'Company Manager'
          ? shop?.shop_id
          : null,
      po_supplier_id: selectedSupplierId,
      company_id: company?.id,
      brand_id: brand?.brand_id,
      shop_id: shop?.shop_id,
      created_by: user?.id,
      total_cost: totalPrice,
      ingredients: update
        ? ingredients?.map((data: any) => ({
            ingredient_id: data?.ingredient_id || data?.id,
            request_quantity: data?.quantity || data?.request_quantity,
            unit_cost: data?.unit_cost,
            total_cost:
              data.unit_cost *
              (data?.quantity || data?.request_quantity) *
              data?.buying_factor,
            is_unit_cost_update: data.is_unit_cost_update || 0,
          }))
        : selectedIngredients?.map((data: any) => ({
            ingredient_id: data?.ingredient_id || data?.id,
            request_quantity: data?.quantity,
            unit_cost: data?.unit_cost,
            total_cost: data.unit_cost * data?.quantity * data?.buying_factor,
            is_unit_cost_update: data.is_unit_cost_update || 0,
          })),
    };
    try {
      // let responseDialogData;

      setApiLoader(true);
      let response;
      if (update) {
        response = await SupplierActions.updateInventorySupply(payload, id);
      } else {
        response = await SupplierActions.createInventorySupply(payload);
      }
      setApiLoader(false);
      if (!response?.success) {
        // responseDialogData = {
        //   OnClick: () => {},
        //   type: 'error',
        //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
        // };
        // ResponseDialog(responseDialogData);
      } else {
        navigate('/app/purchaseOrder');

        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/purchaseOrder');
        //   },
        //   type: 'success',
        //   messageArray: [
        //     ` Your Purchase Order`,
        //     ` has been ${!update ? 'Created' : 'Updated'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Server Error!', 'Something Went Wrong'],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const checkEmptyIngredients = (index: number) => {
    if (index == 2) {
      const wrongIngredient = ingredients.filter((data: any) => {
        if (
          data?.ingredient_id == -1 ||
          data?.quantity == '' ||
          data?.quantity == '0'
        )
          return { unit: data?.carb_per_unit, qty: data?.quantity };
      });

      if (wrongIngredient.length) {
        setErrorMessage([
          'Please ensure all required fields are filled out correctly, and unused fields are removed.',
        ]);
        setError(true);
        setVisible(false);
        window.scrollTo(0, 0);
        return true;
      } else {
        return false;
      }
    }
  };

  //   const removeIngredientName = (index: number) => {
  //     let dataArray = ingredients;
  //     dataArray[index].ingredient_name = '';
  //     setIngredients(() => [...dataArray]);
  //   };

  // const handleIngredientName = (data: string, index: number) => {
  //   let dataArray = ingredients;
  //   dataArray[index].ingredient_name = data;
  //   setIngredients(() => [...dataArray]);
  // };

  // const handleRemoveIngredient = (index: number) => {
  //   let DataArray = ingredients;
  //   DataArray.splice(index, 1);
  //   setIngredients([...DataArray]);
  //   handleTotalPrice(DataArray);
  //   // autoFetchAllergen(DataArray);
  // };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map(
      (data) => +data?.quantity || +data?.request_quantity
    );
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalPrice(price);
  };

  const handleIngredientWeight = (data: any, index: number, label: string) => {
    let dataArray = [...ingredients];
    // let dataArray = ingredients;
    if (label == 'is_unit_cost_update') dataArray[index][label] = data ? 1 : 0;
    else if (data === 0) {
      dataArray[index][label] = '';
    } else {
      dataArray[index][label] = data;
    }
    setIngredients(() => [...dataArray]);
    handleTotalPrice(selectedItems);
  };
  // const handleIngredientWeightUnit = (data: any, index: number) => {
  //   let dataArray = ingredients;
  //   dataArray[index].unit = data?.unit;
  //   dataArray[index].unit_id = data?.id;
  //   dataArray[index].multiplier = data?.multiplier;

  //   setIngredients(() => [...dataArray]);
  // };

  // const AddIngredient = () => {
  //   setIngredients((prevArray) => [
  //     ...prevArray,
  //     {
  //       ingredient_id: -1,
  //       unit_id: 1,
  //       quantity: '',
  //       ingredient_name: '',
  //       storage_unit: 'Kg',
  //       multiplier: 0,
  //     },
  //   ]);
  // };

  // const handleIngredient = (data: any, index: number) => {
  //   let dataArray = ingredients;
  //   const checkDuplicateData = dataArray.some(
  //     (ingredient) => ingredient?.id === data?.id
  //   );

  //   const checkDuplicateField =
  //     dataArray.findIndex(
  //       (ingredientObject) => ingredientObject?.id === data?.id
  //     ) === index;

  //   if (!checkDuplicateField && checkDuplicateData) {
  //     dataArray.splice(index, 1);
  //     setIngredients(() => [...dataArray]);
  //     setErrorMessage([
  //       'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
  //     ]);
  //     setVisible(false);
  //     setError(true);
  //     window.scrollTo(0, 0);
  //     return;
  //   } else if (checkDuplicateField && checkDuplicateData) {
  //     setIngredients([...dataArray]);
  //   } else {
  //     // eslint-disable-next-line no-unused-vars
  //     const unitObject = unit.filter(
  //       (element: any) => element?.id == data?.unit_id
  //     );
  //     dataArray[index] = {
  //       ...data,
  //       quantity: '',
  //       is_unit_cost_update: 0,
  //     };
  //     // autoFetchAllergen(dataArray);
  //     setIngredients(() => [...dataArray]);
  //   }
  // };

  // const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
  //   let option = +e.key;
  //   setDataLength(PageDataLength[+e?.key]);
  //   const unitObject = unit.filter((data: any) => data?.id == option);
  //   handleIngredientWeightUnit(unitObject[0], ingredientIndex);
  // };

  // const DropDownmenuProps = {
  //   items: items,
  //   onClick: handleDropDownClick,
  // };

  //   const fetchIngredients = async () => {
  //     const { data, success } = await SubRecipeActions.getAllSubRecipeInventory(
  //       3,
  //       1,
  //       1000
  //     );
  //     if (success) setIngredientData(data?.items);
  //   };

  // const handleDeleteContinue = (index: number) => {
  //   handleRemoveIngredient(index);
  // };

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const handleCancel = () => {
    setConfirmation(false);
  };
  const handleConfirmation = () => {
    handleIngredientWeight(true, ingredientIndex, 'is_unit_cost_update');
    setConfirmation(false);
    setIngredientIndex(-1);
  };

  const onSelectMultiple = (e: CheckboxChangeEvent, record: any) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, record]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item.id !== record.id));
    }
  };

  const onSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedItems(ingredients);
      setSelectAll(true);
    } else {
      setSelectedItems([]);
      setSelectAll(false);
    }
  };

  const isItemSelected = (record: any) => {
    return selectAll || selectedItems.some((item) => item.id === record.id);
  };

  const Columns = [
    {
      title: () => (
        <div className="tableTitle view-align-brand">
          <span>
            <Checkbox onChange={onSelectAll} checked={selectAll} />
          </span>
        </div>
      ),
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="col3 cellSpacing sub-view-align-adjustment">
          <Checkbox
            onChange={(e: any) => onSelectMultiple(e, record)}
            checked={isItemSelected(record)}
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle view-align-brand">
          <span>INGREDIENT NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="col1 cellSpacing font ">
            <div>
              <div className="subCol1 titleContainer">
                <span className="title sub-view-align-adjustment">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Name</div>
                        <div className="tootipText">
                          {record?.ingredient_name}
                        </div>
                      </div>
                    }
                  >
                    {record?.ingredient_name?.length > 20
                      ? record?.ingredient_name?.substring(0, 20) + '...'
                      : record?.ingredient_name}
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>QUANTITY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('quantity', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing col-qty">
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">QUANTITY</div>
                <div className="tootipText">Valid upto 5 digits</div>
              </div>
            }
          >
            <div className="gramSubContainer po-quantity">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                defaultValue={
                  update
                    ? record.request_quantity
                    : record.quantity
                    ? Number(record.quantity).toString()
                    : ''
                }
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 5) {
                    e.target.value = value.slice(0, 5);
                  }
                  const numericValue = Number(value.slice(0, 5));
                  if (numericValue <= 99999) {
                    handleIngredientWeight(numericValue, index, 'quantity');
                  } else {
                    handleIngredientWeight('', index, 'quantity');
                  }
                }}
                disabled={selectedSupplierId === null}
              />
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('storage_unit', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'storage_unit',
      key: 'storage_unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col2 cellSpacing">{record?.storage_unit}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>ITEM COST</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('unit_cost', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'unit_cost',
      key: 'unit_cost',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing">
          <div className="unit-container-each-ingredient">
            <InputNumber
              className="calorie-each-value-container po-quantity"
              placeholder="0"
              value={
                Number(record?.unit_cost) ? formatNumber(record?.unit_cost) : ''
              }
              disabled={!update}
              onChange={(e: any) => {
                handleIngredientWeight(+e, index, 'unit_cost');
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>TOTAL COST</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('net_cost', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'net_cost',
      key: 'net_cost',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col3 cellSpacing col-qty">
            <div className="calorie-container-each-ingredient">
              <div className="calorie-each-value-container po-quantity">
                {isNaN(
                  record?.unit_cost *
                    record?.buying_factor *
                    (update
                      ? (record?.quantity || record?.request_quantity) === 0 ||
                        record?.quantity === '' ||
                        record?.request_quantity === ''
                        ? 0
                        : record?.quantity || record?.request_quantity
                      : record?.quantity)
                )
                  ? 0
                  : formatNumber(
                      Number(
                        record?.unit_cost *
                          record?.buying_factor *
                          (update
                            ? (record?.quantity || record?.request_quantity) ===
                                0 ||
                              record?.quantity === '' ||
                              record?.request_quantity === ''
                              ? 0
                              : record?.quantity || record?.request_quantity
                            : record?.quantity)
                      )
                    )}
              </div>
            </div>
          </div>
        );
      },
    },
    ...(update
      ? [
          {
            title: () => (
              <div className="tableTitle">
                <span>NEW DEFAULT COST</span>
                <Tooltip title="Tick box to mark your new default cost">
                  <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                </Tooltip>
              </div>
            ),
            dataIndex: '',
            key: '',
            responsive: ['md'],
            render: (_: any, record: any, index: number) => (
              <div className="col3 cellSpacing">
                <Checkbox
                  disabled={!update}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setIngredientIndex(index);
                      setConfirmation(true);
                    } else
                      handleIngredientWeight(
                        e.target.checked,
                        index,
                        'is_unit_cost_update'
                      );
                  }}
                  checked={record?.is_unit_cost_update ? true : false}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  // const onChange: PaginationProps['onChange'] = (page) => {
  //   setCurrent(page);
  // };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Purchase Order"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/purchaseOrder');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Purchase Order">
          <Confirmation
            handleCancel={handleCancel}
            handleContinue={handleConfirmation}
            open={confirmation}
            message={`Are you sure to update unit cost?`}
          />
          {branchAlert && (
            <Alert
              message="Info"
              description="No active supplier for this branch"
              type="info"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setError(false)}
            />
          )}
          {/* {supplierAlert && (
            <Alert
              message="Warning"
              description="Add supplier first"
              type="warning"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setError(false)}
            />
          )} */}
          {visible && (
            <Alert
              message="Error"
              description="Please complete all required fields to proceed"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardSubContainer pocontainer">
            {user?.roles[0]?.id === 3 && (
              <div className="wizardDropdown">
                <div className="wizardLabel">
                  Purchase Order For <span className="sterik">*</span>
                </div>
                <Radio.Group
                  onChange={onChangeBrandBranch}
                  value={purchaseOrderFor}
                  disabled={update}
                >
                  <Radio value="brand">Brand</Radio>
                  <Radio value="shop">Branch</Radio>
                </Radio.Group>
              </div>
            )}
            <div className="wizardDropdown">
              <div className="wizardLabel">
                Supplier <span className="sterik">*</span>
              </div>
              <Select
                style={{ width: '100%' }}
                placeholder="Please Select Supplier"
                value={selectedSupplier || supplierId}
                onChange={handleSupplier}
                // onChange={(e) => {
                //   if (e) {
                //     setSupplierAlert(false);
                //     const selectedSupplier = suppliers.find(
                //       (supplier) => supplier?.value === e
                //     );
                //     if (selectedSupplier) {
                //       setSelectedSupplier(selectedSupplier?.label);
                //       setSelectedSupplierId(e);
                //     }
                //   }
                // }}
                options={suppliers}
                className={
                  errorList['request']?.length > 0 ? 'dropdownUnitColorRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['supplier']}
                </div>
              )}
            </div>
          </div>
          <Row gutter={12} className="listingTopFilter">
            <Col lg={24} md={16} className="Brands-list-wrapper">
              {!apiLoader && selectedSupplierId !== null ? (
                <ListViewWithoutExpand
                  limit={1000}
                  items={''}
                  dataSource={[...sortData]}
                  columns={Columns}
                  component="purchase order"
                  scrollY={600}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin
                    className="loadIngredient recipeBuilderloaderHeight"
                    spinning={apiLoader}
                  />
                </div>
              )}
              {/* {selectedSupplierId !== null ? (
                <Pagination
                  total={total}
                  menuProps={DropDownmenuProps}
                  onChange={onChange}
                  current={current}
                  dataLength={dataLength >= total ? total : dataLength}
                  handleLength={(data: number) => setDataLength(data)}
                />
              ) : null} */}
            </Col>
          </Row>
          <div className="butonGroup po-btn">
            <div className="wizard-button" onClick={() => handleBack()}>
              Back
            </div>
            <div
              className="continue wizard-button"
              onClick={() => handleContinue()}
            >
              Continue
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default PurchaseOrderWizard;
